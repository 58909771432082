// extracted by mini-css-extract-plugin
export var arrowAnimation = "Guarantee-module--arrowAnimation--c7d47";
export var black = "Guarantee-module--black--f0150";
export var blue = "Guarantee-module--blue--2b46e";
export var card = "Guarantee-module--card--eac26";
export var card1 = "Guarantee-module--card1--8fd9c";
export var card2 = "Guarantee-module--card2--3c09d";
export var card3 = "Guarantee-module--card3--b7c2c";
export var colGap = "Guarantee-module--colGap--00a58";
export var concerns = "Guarantee-module--concerns--a4e31";
export var concernsDesc = "Guarantee-module--concernsDesc--c2ea4";
export var concernsHead = "Guarantee-module--concernsHead--35e24";
export var customScale = "Guarantee-module--customScale--971a9";
export var description = "Guarantee-module--description--836e4";
export var heading = "Guarantee-module--heading--57b5e";
export var maindiv = "Guarantee-module--maindiv--3ce4e";
export var newBtn = "Guarantee-module--newBtn--00089";
export var progress = "Guarantee-module--progress--4f96a";
export var progressSec = "Guarantee-module--progressSec--bc928";
export var subDescription = "Guarantee-module--subDescription--7fa4a";
export var subHeading = "Guarantee-module--subHeading--a0c61";
export var talkBtn = "Guarantee-module--talkBtn--51603";
export var webPageHead = "Guarantee-module--webPageHead--cbcbb";
export var white = "Guarantee-module--white--90d96";