// extracted by mini-css-extract-plugin
export var SliderWidth = "WhyChooseOurAIC-module--SliderWidth--fd4a5";
export var Trust = "WhyChooseOurAIC-module--Trust--62133";
export var card = "WhyChooseOurAIC-module--card--1e750";
export var description = "WhyChooseOurAIC-module--description--91849";
export var heading = "WhyChooseOurAIC-module--heading--b7251";
export var headingAndDesc = "WhyChooseOurAIC-module--headingAndDesc--438e2";
export var iconContainer = "WhyChooseOurAIC-module--iconContainer--138c4";
export var iconContainerLeft = "WhyChooseOurAIC-module--iconContainerLeft--2a585";
export var imgContainer = "WhyChooseOurAIC-module--imgContainer--1a245";
export var mobileTitle = "WhyChooseOurAIC-module--mobileTitle--92d25";
export var portfolioArrowIcon = "WhyChooseOurAIC-module--portfolioArrowIcon--c223b";
export var portfolioArrowIconCover = "WhyChooseOurAIC-module--portfolioArrowIconCover--0c921";
export var portfolioArrowRightIconCover = "WhyChooseOurAIC-module--portfolioArrowRightIconCover--f03f1";
export var sheildImgTop = "WhyChooseOurAIC-module--sheildImgTop--b5134";
export var shieldTopBannerImg = "WhyChooseOurAIC-module--shieldTopBannerImg--01138";