import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import Faqs from "../../components/common/Faqs2"
import MainLayout from "../../layouts/MainLayout3"
import Banner from "../../components/ai-consulting-services/Banner"
import WhyChooseOurAIC from "../../components/ai-consulting-services/WhyChooseOurAIC"
import TrustInvoZone from "../../components/application-engineering/OverCountriesProduct"
import AIStatisticsTrend from "../../components/ai-consulting-services/AIStatisticsTrend"
import AIConsultingService from "../../components/ai-consulting-services/AIConsultingServices"
import PerfectEngagement from "../../components/ai-consulting-services/PerfectEngagement"
import Guarantee from "../../components/ai-consulting-services/Guarantee"
import Revolutionizing from "../../components/ai-consulting-services/Revolutionizing"
import SEORevamp from "../../components/common/SEO_Revamp"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}

const artificial = ({ data }) => {
  const heroSection = data?.strapiPage?.sections[0]
  const aiStatisticTrend = data?.strapiPage?.sections[1]
  const consultingService = data?.strapiPage?.sections[2]
  const revolutionizing = data?.strapiPage?.sections[3]
  const whyChoose = data?.strapiPage?.sections[4]
  const guarantee = data?.strapiPage?.sections[5]
  const getStarted = data?.strapiPage?.sections[6]
  const engagementModel = data?.strapiPage?.sections[7]
  const faqs = data?.strapiPage?.sections[8]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={true} schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <TrustInvoZone hideCurve={true} />
      <AIStatisticsTrend strapiData={aiStatisticTrend} />
      <AIConsultingService strapiData={consultingService} />
      <Revolutionizing strapiData={revolutionizing} />
      <WhyChooseOurAIC strapiData={whyChoose} />
      <Guarantee strapiData={guarantee} strapiData1={getStarted} />
      <PerfectEngagement strapiData={engagementModel} />
      <Faqs strapiData={faqs} pageName="Staff Augmentation Company" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query artificial {
    strapiPage(slug: { eq: "artificial-intelligence-consulting-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          jsonData {
            aiPowered {
              services {
                serLink
                serName
              }
            }
          }
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default artificial

