// extracted by mini-css-extract-plugin
export var Trust = "Revolutionizing-module--Trust--9008b";
export var cardContent = "Revolutionizing-module--cardContent--e44f0";
export var cardCounting = "Revolutionizing-module--cardCounting--82bd3";
export var cardDescription = "Revolutionizing-module--cardDescription--8a9ed";
export var cards = "Revolutionizing-module--cards--c2d44";
export var container = "Revolutionizing-module--container--8d8b2";
export var cta = "Revolutionizing-module--cta--0e17e";
export var description = "Revolutionizing-module--description--27cae";
export var heading = "Revolutionizing-module--heading--5ac51";
export var slideWrapper = "Revolutionizing-module--slideWrapper--85e68";